export default {
  forms: [
    {
      id: "services",
      title: {
        add: "Add Service(s)",
        edit: "Reconfigure - Edit Service(s)",
      },
      subtitle: {
        add: "Which service(s) do you want to add to the customer’s subscription?",
        edit: "Which of these service(s) in the customer's subscription do you want to reconfigure?",
      },
    },
    {
      showIf(services) {
        return services.includes("beauty");
      },
      id: "carebox",
      title: {
        add: "Add Beauty Configuration",
        edit: "Edit Beauty Configuration",
      },
      subtitle: {
        add: "Set customer's Beauty Configuration",
        edit: "Set customer's new Beauty Configuration",
      },
      component: "beauty-configuration",
    },
    {
      showIf(services) {
        return services.includes("cleaning");
      },
      id: "cleaning",
      title: {
        add: "Add Cleaning Configuration",
        edit: "Edit Cleaning Configuration",
      },
      subtitle: {
        add: "Set customer's Cleaning Configuration",
        edit: "Set customer's new Cleaning Configuration",
      },
      component: "cleaning-configuration",
    },
    {
      showIf(services) {
        return services.includes("laundry");
      },
      id: "laundry",
      title: {
        add: "Add Laundry Configuration",
        edit: "Edit Laundry Configuration",
      },
      subtitle: {
        add: "Set customer's Laundry Configuration",
        edit: "Set customer's new Laundry Configuration",
      },
      component: "laundry-configuration",
    },
    {
      showIf(services) {
        return services.includes("meal");
      },
      id: "meal",
      title: {
        add: "Add Food Configuration",
        edit: "Edit Food Configuration",
      },
      subtitle: {
        add: "Set customer's Food Configuration",
        edit: "Set customer's new Food Configuration",
      },
      component: "meal-configuration",
    },
  ],
};
