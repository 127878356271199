<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="shouldShow"
      direction="rtl"
      @close="closeEvent"
    >
      <div v-loading="loading">
        <div class="el-drawer--content">
          <div class="el-drawer--content__heading is-align-center">
            <eden-avatar :size="60" class="mb-1" />
            <p class="font-md">{{ formatName(subscription.name) }}</p>
            <span class="font-base text-grey-tertiary">{{
              subscription.email
            }}</span>
          </div>
          <div class="el-drawer--content__body">
            <template>
              <el-row type="flex">
                <el-col :span="24">
                  <div class="properties">
                    <div
                      v-for="(property, index) in properties"
                      :key="index"
                      class="property"
                    >
                      <p>{{ property.label }}</p>
                      <span>{{ property.value }}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" class="flex-wrap">
                <el-col :span="24">
                  <subscription-service
                    v-for="(value, key, index) in subscription.plan"
                    :key="index"
                    :service="key"
                    :service-config="value"
                    :services-count="
                      servicesCount[key] && servicesCount[key].services_count
                    "
                    :services-received-count="servicesReceivedCount[key]"
                  />
                </el-col>
              </el-row>
            </template>
          </div>
        </div>
        <div class="el-drawer--footer">
          <div class="w-100">
            <el-alert
              v-if="isNextChargeDateInvalid"
              type="info"
              class="small mb-1"
            >
              <template slot="title">
                <div class="el-alert--content">
                  <p>
                    Customer's next charge date is a past date. Please update to
                    make changes to subscription.
                  </p>
                </div>
              </template>
            </el-alert>
            <el-row type="flex" class="w-100">
              <el-col :span="24" class="is-flex is-justify-center">
                <el-button
                  v-if="!email.includes('deleted_account')"
                  class="w-100"
                  plain
                  icon="eden-icon-edit"
                  @click="showSubscriptionConfigurationEdit = true"
                  :disabled="isNextChargeDateInvalid"
                  >Edit services</el-button
                >
                <el-button
                  v-if="!email.includes('deleted_account')"
                  class="w-100"
                  plain
                  icon="el-icon-plus"
                  :disabled="isNextChargeDateInvalid"
                  @click="add"
                  >Add</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-drawer>
    <subscription-configuration-add
      :show.sync="showSubscriptionConfigurationAdd"
      :subscription="subscription"
      @added="updateSubscription"
      @exit="showSubscriptionConfigurationAdd = false"
      :menu-type-variation="menuTypeVariation"
    />
    <subscription-configuration-edit
      :show.sync="showSubscriptionConfigurationEdit"
      :subscription="subscription"
      @updated="updateSubscription"
      @exit="showSubscriptionConfigurationEdit = false"
      :menu-type-variation="menuTypeVariation"
    />
  </div>
</template>

<script>
import SubscriptionService from "@/components/Subscriptions/Subscription/SubscriptionService";
import SubscriptionConfigurationAdd from "./Reconfigration/SubscriptionConfigurationAdd";
import SubscriptionConfigurationEdit from "./Reconfigration/SubscriptionConfigurationEdit";

import subscription from "@/requests/subscriptions/subscription";

export default {
  name: "Subscription",
  components: {
    SubscriptionService,
    SubscriptionConfigurationAdd,
    SubscriptionConfigurationEdit,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    subscription: {
      type: Object,
      default() {
        return {};
      },
    },
    menuTypeVariation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      view: "overview",
      service: "",
      action: "",
      subscriptionCycleServicesCount: {},
      showSubscriptionConfigurationEdit: false,
      showSubscriptionConfigurationAdd: false,
      servicesReceivedCount: {},
      servicesCount: {},
      email: "",
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      const { start_date, next_charge_date: end_date = "" } = this.subscription;
      const { start, end } = this.formatSubscriptionDate(start_date, end_date);
      return `Subscription (${start} - ${end})`;
    },
    properties() {
      const { created_at, updated_at, amount, next_charge_date } =
        this.subscription;
      return [
        {
          label: "Created",
          value: this.formatDateAndTime(created_at, "mo d, y"),
        },
        {
          label: "Updated",
          value: this.formatDateAndTime(updated_at, "mo d, y"),
        },
        {
          label: "Amount",
          value: this.formatPrice(amount),
        },
        {
          label: "Next Charge Date",
          value: this.formatDate(next_charge_date, "mo d, y"),
        },
      ];
    },
    isNextChargeDateInvalid() {
      if (
        this.show &&
        (this.allowAccessFor(["superadmin"]) ||
          this.allowAccessFor(["gardener_pro"]))
      ) {
        return false;
      }
      const today = this.formatDate(new Date(), "y-m-d");
      const chargeDate = this.formatDate(
        this.subscription.next_charge_date,
        "y-m-d",
      );

      if (today === chargeDate) {
        return false;
      }
      return (
        new Date(this.subscription.next_charge_date).getTime() <
        new Date().getTime()
      );
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.view = "overview";
        const { id } = this.subscription;
        this.email = this.subscription.email;
        this.getServicesCount(id);
        this.getServicesReceivedCount(id);
      }
    },
  },
  methods: {
    getServicesCount(id) {
      subscription
        .servicesCount(id)
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data;
            this.servicesCount = data.services_count;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getServicesReceivedCount(id) {
      subscription
        .servicesReceived(id)
        .then((response) => {
          if (response.data.status) {
            this.servicesReceivedCount = response.data.data;
            this.loading = false;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    edit() {
      this.showSubscriptionConfigurationEdit = true;
    },
    add() {
      const services = this.$store.getters.services.map((service) =>
        service.service_name.toLowerCase(),
      );
      const customerServices = Object.keys(this.subscription.plan);

      if (services.length === customerServices.length) {
        this.$message.info({
          message: "No new service to add",
          duration: 2000,
        });
        return;
      }
      this.showSubscriptionConfigurationAdd = true;
    },
    closeEvent() {
      this.shouldShow = false;
      this.action = "";
      this.view = "";
    },
    updateSubscription() {
      this.shouldShow = false;
      this.$emit("reconfigured");
      this.closeEvent();
    },
  },
};
</script>

<style lang="scss" scoped>
.properties {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 20px;

  .property {
    width: 50%;
    margin-bottom: 20px;

    p {
      font-size: 0.825rem;
      color: var(--eden-grey-quaternary);
    }

    span {
      color: var(--eden-grey-primary);
      display: block;
      margin-top: 3px;
      font-size: 0.875rem;
    }
  }
}

.el-drawer--content {
  .property {
  }
}
</style>
