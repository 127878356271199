<template>
  <div>
    <el-row type="flex" v-for="(value, key, index) in plan" :key="index">
      <el-col :span="24">
        <component
          :is="`${key}-configuration-summary`"
          :data="value"
          :added="isServiceAdded(key)"
          :edited="isServiceEdited(key)"
        />
      </el-col>
    </el-row>
    <div class="eden-plan--summary total">
      <div>
        <span>Total</span>
        <p>
          {{ formatPrice(amount) }}
        </p>
      </div>
    </div>
    <el-row type="flex">
      <el-col :span="24">
        <el-form :model="form" label-position="top" ref="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Start date"
                prop="start_date"
                :rules="validateField()"
              >
                <el-date-picker
                  v-model="form.start_date"
                  :clearable="false"
                  type="date"
                  format="dd MMM, yyyy"
                  value-format="yyyy-MM-dd"
                  :picker-options="{
                    disabledDate: disabledDates,
                  }"
                  prefix-icon="eden-icon--calendar"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import BeautyConfigurationSummary from "../Configuration/Beauty/BeautyConfigurationSummary";
import CleaningConfigurationSummary from "../Configuration/Cleaning/CleaningConfigurationSummary";
import LaundryConfigurationSummary from "../Configuration/Laundry/LaundryConfigurationSummary";
import MealConfigurationSummary from "../Configuration/Meal/MealConfigurationSummary";

import subscription from "@/requests/subscriptions/subscription";

export default {
  name: "SubscriptionConfigurationSummary",
  props: {
    action: {
      type: String,
      required: true,
    },
    currentPlan: {
      type: Object,
      default() {
        return {};
      },
    },
    formResponses: {
      type: Object,
      default() {
        return {};
      },
    },
    servicesToProvision: {
      type: Array,
      default() {
        return {};
      },
    },
    customerEmail: {
      type: String,
      required: true,
    },
    nextChargeDate: {
      type: String,
      required: true,
    },
    submit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BeautyConfigurationSummary,
    CleaningConfigurationSummary,
    LaundryConfigurationSummary,
    MealConfigurationSummary,
  },
  data() {
    return {
      form: {
        start_date: "",
      },
      amount: 0,
      plan: {},
      pendingServicesCount: {},
      pendingServicesAmount: 0,
      adding: false,
      updating: false,
    };
  },
  computed: {
    setSubmit: {
      get() {
        return this.submit;
      },
      set(value) {
        this.$emit("update:submit", value);
      },
    },
  },
  watch: {
    submit() {
      if (this.submit) {
        this.$refs.form.validate(async (valid) => {
          if (!valid) {
            this.setSubmit = false;
            return false;
          }
          this.reconfigure();
          return true;
        });
      }
    },
  },
  created() {
    this.formatData();
  },
  methods: {
    isServiceAdded(service) {
      return (
        this.action === "add" && this.servicesToProvision.includes(service)
      );
    },
    isServiceEdited(service) {
      return (
        this.action === "update" && this.servicesToProvision.includes(service)
      );
    },
    prev() {
      this.$emit("back");
    },
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin", "gardener_pro"])) {
        return false;
      }
      return (
        time.getTime() < new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      );
    },
    formatData() {
      this.plan = { ...this.currentPlan, ...this.formResponses };

      this.amount = Object.keys(this.plan).reduce((total, service) => {
        return total + this.plan[service].amount;
      }, 0);
    },
    formatPayload() {
      return {
        start_date: this.form.start_date,
        amount: this.amount,
        plan: JSON.stringify(this.sortObject(this.plan)),
        email: this.customerEmail,
        service_to_provision:
          this.action === "add"
            ? Object.keys(this.formResponses)
            : this.servicesToProvision,
        new_onboarding_flow: true,
      };
    },
    reconfigure() {
      this.updating = true;
      const payload = this.formatPayload();

      subscription
        .reconfigure(this.action, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.updating = false;
            this.setSubmit = false;
            this.$emit(this.action === "add" ? "added" : "updated");
          } else {
            this.setSubmit = false;
            this.$message.error(response.data.message);
            this.updating = false;
          }
        })
        .catch((error) => {
          this.setSubmit = false;
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-plan--summary {
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 20px;

  &.edited {
    border: 1px solid rgba(0, 77, 41, 0.7);
  }

  &.total {
    background: #deffef;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  span {
    color: #798b83;
    width: 40%;
    font-size: 0.875rem;
  }

  p {
    color: #21312a;
    font-size: 1rem;
  }
}

.eden-plan--info {
  width: 100%;
  background: #deffef;
  padding: 20px;
  border-radius: 8px;

  span {
    font-weight: 600;
    color: #21312a;
  }

  p {
    font-size: 1rem;
    line-height: 1.8;
    color: #57645e;
  }
}
</style>
