<template>
  <div class="subscription-service">
    <el-collapse accordion>
      <el-collapse-item
        :name="service"
        :itemprop="serviceConfig"
        :class="service.toLowerCase()"
      >
        <template slot="title">
          <div class="subscription-service-title">
            <span class="service">
              {{ formatText(service) }}
            </span>
            <span v-if="!hideCount">
              <span class="count">
                {{ servicesReceivedCount }} / {{ servicesCount }} services in
                this cycle
              </span>
            </span>
          </div>
        </template>
        <div class="subscription-service-config">
          <div class="item">
            <span> Type </span>
            <span>
              {{ formatServiceType({ item, frequency, service }) }}
            </span>
          </div>
          <div class="item">
            <span> Frequency </span>
            <span>
              {{ frequencyLabel(serviceConfig.frequency) }}
            </span>
          </div>
          <div class="item">
            <span> Service day(s) </span>
            <span v-if="service === 'meal' && serviceConfig.meal_per_delivery">
              {{ formatMealServiceDays(serviceConfig.meal_per_delivery) }}
            </span>
            <span v-else>
              {{ formatServiceDay(serviceConfig.service_day) }}
            </span>
          </div>
          <template v-if="['beauty', 'laundry'].includes(service)">
            <div class="item">
              <span>Quantity</span>
              <span>{{
                formatServiceUnit(service, serviceConfig.qty, true)
              }}</span>
            </div>
          </template>
          <template v-if="service === 'cleaning'">
            <div class="item">
              <span>Home type</span>
              <span>{{ formatText(serviceConfig.home_type) }}</span>
            </div>
            <div class="item">
              <span>Item areas</span>
              <span>{{ formatItemAreas(serviceConfig.item_areas) }}</span>
            </div>
          </template>
          <div class="item">
            <span>Amount</span>
            <span>{{ formatPrice(serviceConfig.amount) }}</span>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "SubscriptionService",
  props: {
    service: {
      type: String,
      default: "",
    },
    serviceConfig: {
      type: Object,
      required: true,
    },
    servicesCount: {
      type: Number,
      default: 0,
    },
    servicesReceivedCount: {
      type: Number,
      default: 0,
    },
    hideCount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    item() {
      return this.serviceConfig.item;
    },
    frequency() {
      return this.serviceConfig.frequency;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-service {
  padding-bottom: 12px;
  transform: translateY(-2px);

  .subscription-service-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .service {
      font-weight: 500;
      font-size: 0.825rem;
      color: var(--eden-grey-primary);
    }

    .count {
      font-size: 0.825rem;
      margin-right: 5px;
    }

    span {
      color: var(--eden-grey-primary);
    }
  }

  .subscription-service-config {
    margin-top: 20px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: var(--eden-grey-secondary);

      span {
        font-size: 0.825rem;
        line-height: 20px;
        width: 50%;

        &:last-child {
          margin-right: 10px;
          width: 300px;
          text-align: right;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.el-collapse {
  border: 0;

  &-item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
</style>
