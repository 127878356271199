<template>
  <el-drawer
    :title="showSummary ? 'Summary' : title"
    :visible.sync="setShow"
    direction="rtl"
    @close="closeEvent"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__heading">
        <p v-if="currentFormIndex === 0" class="mb-1">
          <span class="text-black text-bold">Note: </span>Select only the
          service you want to reconfigure.
        </p>
        <p v-if="showSummary">
          Note: The highlighted service(s) are the edited services in the
          customer's plan.
        </p>
        <p v-else>{{ forms[currentFormIndex].subtitle.edit }}</p>
      </div>
      <div class="el-drawer--content__body">
        <template v-if="currentFormIndex === 0">
          <el-checkbox-group
            v-model="form.services"
            class="eden-custom--radio large"
          >
            <el-checkbox
              v-for="(service, i) in services"
              :key="i"
              :label="service"
              @change="updateFormResponses(service)"
            >
              <div class="checkbox">
                <img
                  class="icon"
                  :src="getImage(`${service}.svg`)"
                  :alt="service"
                />
                <span>{{ formatText(service) }}</span>
              </div></el-checkbox
            >
          </el-checkbox-group>
        </template>
        <template v-else>
          <template v-if="!showSummary">
            <transition name="fade" mode="out-in">
              <component
                :is="forms[currentFormIndex].component"
                :config="formResponses[service]"
                @update="updateConfig"
                :menu-type-variation="menuTypeVariation"
              />
            </transition>
          </template>
          <template v-else>
            <subscription-configuration-summary
              action="update"
              :current-plan="currentPlan"
              :form-responses="formResponses"
              :services-to-provision="form.services"
              :customer-email="subscription.email"
              :next-charge-date="subscription.next_charge_date"
              :submit.sync="submit"
              @updated="updated"
            />
          </template>
        </template>
      </div>
      <div class="el-drawer--footer">
        <el-button plain @click="navigateToForm(currentFormIndex - 1)"
          >Back</el-button
        >
        <el-button
          v-if="!showSummary"
          type="primary"
          :disabled="form.services.length === 0"
          @click="navigateToForm(currentFormIndex + 1)"
          >Next</el-button
        >
        <el-button
          v-else
          type="primary"
          :loading="submitting"
          @click="submit = true"
          >Submit</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>

<script>
import config from "../Reconfigration/reconfiguration.config";
import SubscriptionConfigurationSummary from "./SubscriptionConfigurationSummary";

export default {
  name: "SubscriptionConfigurationEdit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    subscription: {
      type: Object,
      default() {
        return {};
      },
    },
    menuTypeVariation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    SubscriptionConfigurationSummary,
  },
  data() {
    return {
      form: {
        services: [],
      },
      forms: config.forms,
      currentFormIndex: 0,
      currentPlan: {},
      formResponses: {},
      showSummary: false,
      submit: false,
      submitting: false,
    };
  },
  computed: {
    services() {
      return this.subscription.plan ? Object.keys(this.subscription.plan) : [];
    },
    service() {
      return this.forms[this.currentFormIndex].component.split("-")[0];
    },
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.forms[this.currentFormIndex].title["edit"];
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.formResponses = { ...this.subscription.plan };
        this.setTitle = this.forms[this.currentFormIndex].title["edit"];
      }
    },
    submit() {
      this.submitting = this.submit;
    },
  },
  methods: {
    closeEvent() {
      this.currentFormIndex = 0;
      this.form.services = [];
      this.formResponses = {};
      this.showSummary = false;
      this.setShow = false;
    },
    updateFormResponses(service) {
      if (!this.form.services.includes(service)) {
        delete this.formResponses[service];
      }
    },
    setPlan() {
      const added = this.form.services.filter(
        (service) => !Object.keys(this.formResponses).includes(service),
      );

      if (added.length) {
        this.formResponses[added[0]] = {
          frequency: "",
          item: added[0] === "meal" ? null : "",
          qty: "",
          service_day: [],
          amount: 0,
        };
        return;
      }

      const removed = Object.keys(this.formResponses).filter(
        (service) => !this.form.services.includes(service),
      );
      this.updatePlan(removed[0]);
    },
    updatePlan(service) {
      delete this.formResponses[service];
    },
    updateConfig({ response, service }) {
      this.formResponses[service] = { ...response };
    },
    saveForm(form) {
      this.formResponses = { ...this.formResponses, ...form };
      this.gotoNextForm();
    },
    hideForm(formIndex) {
      return (
        this.forms[formIndex].showIf &&
        !this.forms[formIndex].showIf(this.form.services)
      );
    },
    /**
     * Forms navigation - attempt to navigate to a form
     * If the form should not be displayed, go to the next form until
     * we encounter a from that should be displayed
     */
    navigateToForm(formIndex) {
      this.showSummary = false;
      if (formIndex < 0) {
        this.$emit("exit");
        return;
      }

      if (formIndex >= this.forms.length) {
        this.showSummary = true;
        this.setTitle = "Subscription Summary";
        return;
      }

      const navigate =
        formIndex < this.currentFormIndex
          ? this.gotoPrevForm
          : this.gotoNextForm;

      if (this.hideForm(formIndex)) {
        navigate(formIndex);
        return;
      }
      this.currentFormIndex = formIndex;
    },
    gotoNextForm(currentFormIndex) {
      const nextFormIndex = (currentFormIndex || this.currentFormIndex) + 1;
      if (nextFormIndex >= this.forms.length) {
        this.showSummary = true;
        this.setTitle = "Subscription Summary";
        return;
      }
      this.navigateToForm(nextFormIndex);
    },
    gotoPrevForm(currentFormIndex) {
      const prevFormIndex = (currentFormIndex || this.currentFormIndex) - 1;
      if (prevFormIndex < 0) {
        this.$emit("exit");
        return;
      }
      this.navigateToForm(prevFormIndex);
    },
    updated() {
      this.setShow = false;
      this.$emit("updated");
    },
  },
};
</script>

<style scoped></style>
