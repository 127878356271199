<template>
  <div :class="['eden-plan--summary', { added: added, edited: edited }]">
    <div>
      <p>Beauty</p>
    </div>
    <div>
      <span>Type</span>
      <p>{{ formatText(data.item) }}</p>
    </div>
    <div>
      <span>Frequency</span>
      <p>{{ formatText(frequencyLabel(data.frequency)) }}</p>
    </div>
    <div v-if="data.service_day">
      <span>Service day</span>
      <p>{{ formatToTitleCase(data.service_day.join(", ")) }}</p>
    </div>
    <div v-if="data.dates">
      <span>Dates</span>
      <p>{{ data.dates.join(", ") }}</p>
    </div>
    <div>
      <span>Quantity</span>
      <p>{{ formatServiceUnit("laundry", data.qty, true) }}</p>
    </div>
    <div>
      <span>Amount</span>
      <p class="text-bold">
        {{ formatPrice(data.amount) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeautyConfigurationSummary",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    added: {
      type: Boolean,
      default: false,
    },
    edited: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.eden-plan--summary {
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 20px;

  &.added,
  &.edited {
    border: 1px solid rgba(0, 77, 41, 0.7);
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  span {
    color: #798b83;
    width: 40%;
    font-size: 0.875rem;
  }

  p {
    color: #21312a;
    font-size: 1rem;
  }
}
</style>
