import axios from "axios";

export default {
  get(email) {
    return axios.get(`subscriptions/${email}`);
  },

  update(id, payload) {
    return axios.put(`subscriptions/${id}`, payload);
  },

  servicesCount(id) {
    return axios.get(`subscriptions/${id}/services_metadata/current_cycle`);
  },

  servicesReceived(id) {
    return axios.get(`subscriptions/${id}/services_delivered/current_cycle`);
  },

  setPendingPlan(id, payload) {
    return axios.put(`subscriptions/${id}/pending_plan`, payload);
  },

  getPendingPlan(id) {
    return axios.get(`subscriptions/${id}/queued_pending_plan`);
  },

  getCompanySubscriptions(id){
    return axios.get(`crm/customer/business/${id}/subscriptions`)
  },

  getSubscriptionCycles(id) {
    return axios.get(`subscriptions/all_cycles/${id}`);
  },

  reconfigure(action, payload) {
    return axios.post(`customer/subscription/${action}_services`, payload);
  },

  renew(payload) {
    return axios.post(`renew_subscription_via_gardener_trigger`, payload);
  },

  cycle(payload) {
    return axios.post(
      "set_orders_in_cycle_for_subscription_migration",
      payload
    );
  },
  corporateCycle(payload) {
    return axios.post(
      "set_orders_in_cycle_for_corporate_subscription",
      payload
    );
  },
  pricing() {
    return axios.get("pricing_config");
  },
  delete(email) {
    return axios.delete(`subscriptions/${email}`);
  },
  export() {
    return axios.get("subscriptions/export/all");
  },
};
